
.h-16 {
  height: 4rem;
}

.h-full {
  height: 100%;
}

.rootContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  display: flex;
  flex-grow: 1;
}

.page {
  min-height: 100vh;
}

.cursor {
  cursor: pointer;
}

.imgheight {
  height: 25px;
  width: 25px;
  margin-left: 10px;
}

.sidenav {
 
  width: 16rem;

  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px #0000001A;

border-radius: 8px




}

/* th {
  background: #315E7F;
  cursor: default;
  display: table-cell;
  font-size: 13px;
  font-weight: 700;
  height: 43px;
  line-height: 19.5px;
  padding: 0px 22px;
  text-align: left;
  color: #ffffff;
}

.custom-table {
  border-radius: 8px;
  border: 1px solid #E5E5EF;
 
  margin-top: 20px;
 
  border-collapse: collapse;

  width: 100%;

}


.custom-table th,
.custom-table td {
  border: 1px solid #ddd;
  padding: 8px;
} */

.custom-table th {
  cursor: pointer;
}

.editIcon {
  height: 20px;
  cursor: pointer;
}

.edit-block {
  width: 150px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.position-absolute {
  position: absolute;
}
.pagination{
  display: flex;
    justify-content: flex-end;
}


.pagination .active {
  background-color:  #88E9E8 ;
  border:1px solid  #88E9E8 ;
  color: white;
  border-radius: 4px;
}

/* .custom-table td {
  font-size: 13px;
  font-weight: 200;
  height: 18px;
  line-height: 19.5px;
  padding: 10px 25px;
  text-align: left;
  text-indent: 0px;
  margin: auto;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  color: rgb(51, 55, 64);
  border-top: 1px solid #e0e0e0;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.custom-table thead th:first-child {
  border-top-left-radius: 8px;
}

.custom-table thead th:last-child {
  border-top-right-radius: 8px;
}

.custom-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

.custom-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
} */

/* th {
 background: #315E7F;
  
  cursor: default;
  display: table-cell;
  font-size: 13px;
  font-weight: 700;
  height: 43px;
  line-height: 19.5px;
  padding: 0px 22px;
text-align: left;
  color: #ffffff
 
}
table {
  border-radius: 3px;
  box-shadow: rgb(227 233 243) 0px 2px 4px;
  table-layout: fixed;

  margin-top: 20px;
}
td {
  font-size: 13px;
  font-weight: 200;
  height: 18px;
  line-height: 19.5px;
  padding: 10px 25px;
  text-align: left;
  text-indent: 0px;
  margin: auto;
  box-sizing: border-box;
  font-family: "Lato" !important ;
  color: rgb(51, 55, 64);
  border-top: 1px solid #e0e0e0;
  white-space: nowrap;
  text-overflow: ellipsis;
} */
.editIcon {

  height: 20px;

  
}

.btn-primary {
  background-color: #50B8BA !important;
  border-color: #50B8BA !important;
  color: black !important;
}
.page-link.active, .active > .page-link {
background-color: #50B8BA !important;
border-color: #50B8BA !important;
}

.loginlayout{
 
  background-color: #315E7F;
  height: 100%;
  padding: 50px;
}
/* .loginlayout{
  border:1px solid #d3e0b3 !important;
  background-color: #d3e0b3 !important
} */
.mt-9{
  margin-top: 3rem !important;
}
.ml-2{
  margin-left: 2px;
}
.custom-hr {
  border: 1px solid #ffffff !important;
  height: 40px;
  margin-left: 4px;
  
}

.navbarStyling{
  height: 80px;
  padding: 20px;
  background: linear-gradient(90deg, #49BBB9 0%, #253163 100%);
  display: flex;
  justify-content: space-between;

  box-shadow: 0px 4px 20px 0px #0000001A;

}
.active-menu {
   background: #88E9E8;
   width: 100%;
   padding: 4px;
   /* Change to your desired active background color */
  border-radius: 4px;
}

.content {
  display: flex;
  flex-grow: 1;
  padding: 20px;
  overflow: hidden; /* Ensure that content doesn't overflow */
}

.sidenav {
  width: 16rem;
  flex: 0 0 16rem; /* Ensure fixed width for side menu */
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px #0000001A;
  border-radius: 8px;
  z-index: 100; /* Ensure the side menu appears above the content */
}

.outletcard {
  flex-grow: 1; /* Allow the content to take up remaining space */
  margin-left: 20px; /* Add space between sidemenubar and outlet */
  padding: 20px;
  background-color: #ffffff; /* Background color for the outlet content */
  box-shadow: 0px 2px 12px 0px #0000001A;
  border-radius: 5px; /* Rounded corners for outlet */
}



.editIcon {
  height: 20px;
  cursor: pointer;
}

.edit-block {
  width: 150px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

.position-absolute {
  position: absolute;
}

.custom-table {
  border-radius: 8px;
  border: 1px solid #E5E5EF;
  margin-top: 20px;
  
  border-collapse: separate;
  border-spacing: 0; /* This removes the space between cells */
  width: 100%;
  
}

.custom-table td {
  font-size: 13px;
  font-weight: 200;
  height: 18px;
  line-height: 19.5px;
  padding: 10px 25px;
  text-align: left;
  text-indent: 0px;
  margin: auto;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  color: rgb(51, 55, 64);
  border-top: 1px solid #e0e0e0;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-left: none; /* Remove left border */
  border-right: none; /* Remove right border */
}

.custom-table th {
  background: #315E7F;
  cursor: default;
  display: table-cell;
  
  font-size: 13px;
  font-weight: 700;
  height: 43px;
  line-height: 19.5px;
  padding: 0px 22px;
  text-align: left;
  color: #ffffff;
  border-left: none; /* Remove left border */
  border-right: none; /* Remove right border */
}

.custom-table thead th:first-child {
  border-top-left-radius: 8px;
}

.custom-table thead th:last-child {
  border-top-right-radius: 8px;
}

.custom-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

.custom-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

 .containers{
  background: #FFFFFF;
  box-shadow: 0px 0px 30px 0px #0000001A;
 
  border-radius: 8px;
  opacity: 0px;
  padding: 30px;
  height: 100%;
  width: 100%;
  background-color: red;
  
} 
/* .layout{
  height: calc(100vh-'80px');
  overflow-y: hidden;
  background-color: #315E7F;
  
} */
.layout {
  height: calc(100vh - 80px);
  overflow-y: hidden;
  background-color: #315E7F;
  

}




.login-container {
  background: #FFFFFF;
  box-shadow: 0px 0px 30px 0px #0000001A;
  border-radius: 8px;
  padding: 30px;
  width: 100%;
  min-width: 1000px; /* Ensure the login container doesn't get too wide */
}

/* Card styling inside the login container */
.card {
  border: none;
  box-shadow: 0px 2px 12px 0px #0000001A;
  border-radius: 8px;
  padding: 16px; /* Ensure padding inside the card */
}

/* Styling for buttons */
.btn-primary {
  background-color: #88E9E8 !important;
  border-color: #88E9E8 !important;
  color: black !important;
}

/* Margin utilities */
.mt-2 {
  margin-top: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}



/* Height utilities */
.h-100 {
  height: 100% !important;
}

/* Extra padding for the login layout */
.loginlayout {
  padding: 16px;
  border:1px solid #f8f9fa;
 /* Example background color for the login form section */
}


.a{
  background: #f8f9fa;
    width: 100%;
    height: calc(100vh - 80px);
  overflow-y: hidden;
  padding: 2rem;
}

.b{
  border:1px solid #f8f9fa;
  background: #FFFFFF;
  border-radius: 8px !important;
  box-shadow: 0px 2px 12px 0px #0000001A;
}

/* .col-md-7{
background-color: #315E7F;
}
.col-md-5{
  background-color: #6b808f;
  } */

  /* .c{
    border: 1px solid #B1B1B1;
    border-radius: 8px;
    padding: 85px 60px;
  } */
.c{
  margin-top: 5px;
}
  .custom-hrs{
    border: 1px solid #E6E6E6;
    height: 90%
    ;
  }
.layoutlogin{
  padding: 40px;
}
  .custom-select-height {
    height: 50px;
    cursor: pointer;
  }
  .form{
    margin-bottom: 16px;
  }